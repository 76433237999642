<template>
<div></div>
</template>
<script>
import LiffChecker from "@/utils/LiffChecker";
import abbottAndMixin from "@/mixins/liff/proj/abbott-and";
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [abbottAndMixin],
  components: {},
  async mounted() {
    const query = this.$route.query;
    this.setQuery(
      {
        phone: query.phone,
        source: query.source,
        coupon_code: query.couponCode,
        qrcode: query.qrcode,
        store_code: query.storeCode,
        brand: query.brand,
        event_name: query.eventname,
        type: this.$route.params.couponType
      }
    )
    const checker = new LiffChecker(this.$route.params.orgCode);
    this.lineData = await checker.getLineData();
    this.init();
  },
  data() {
    return {
      lineData: {},
    };
  },
  computed: {
    ...mapGetters({
      queryData: "projAbbottAnd/queryData",
      couponType: "projAbbottAnd/couponType",
    }),
  },
  methods: {
    ...mapActions('projAbbottAnd', ['setQuery']),
    init() {
      this.checkMember(this.lineData.profile.userId)
    },
  }
}
</script>

<style lang="scss"></style>
